import axios from "axios";
import Logger from "../logger";

const CSRFManager = (() => {
  const logger = Logger.create("CSRF");
  let isRefreshing = false;
  let failedQueue = [];
  const MAX_RETRIES = 3;

  const setCsrfToken = token => {
    if (!token) return;

    logger.time("Token Update");
    axios.defaults.headers.common["X-CSRF-Token"] = token;
    const metaTag = document.querySelector("meta[name='csrf-token']");
    if (metaTag && metaTag.setAttribute) metaTag.setAttribute("content", token);
    logger.timeEnd("Token Update");
    logger.log("Token updated:", token);
  };

  const getCsrfToken = () => {
    return document.querySelector("meta[name='csrf-token']")?.content || "";
  };

  const updateCsrfToken = () => {
    const token = getCsrfToken();
    if (token) setCsrfToken(token);
  };

  const processQueue = (error = null) => {
    failedQueue.forEach(({ resolve, reject }) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
    failedQueue = [];
  };

  const refreshCsrfToken = async () => {
    try {
      logger.log("Starting token refresh request");
      logger.log("Current token:", getCsrfToken());

      const response = await axios.get("/api/v1/browse/users/refresh_csrf");
      const newToken = response.data.csrf_meta_tags?.csrf_token;
      if (!newToken) throw new Error("No CSRF token received from server");

      setCsrfToken(newToken);
      return newToken;
    } catch (error) {
      logger.error("Token refresh failed:", error);
      throw error;
    }
  };

  const handleCsrfError = async error => {
    if (error.response?.status !== 422 || error.response?.data?.code !== "INVALID_CSRF_TOKEN") {
      return Promise.reject(error);
    }

    const { config } = error;
    config.retryCount = config.retryCount || 0;

    if (config.retryCount >= MAX_RETRIES) {
      logger.error("Max retries reached for:", config.url);
      return Promise.reject(new Error("[CSRF] Max retry attempts reached"));
    }

    if (config.url.includes("/refresh_csrf")) {
      return Promise.reject(error);
    }

    config.retryCount += 1;
    logger.log(`Retry attempt ${config.retryCount} of ${MAX_RETRIES} for ${config.url}`);

    if (isRefreshing) {
      logger.log(`Request queued while token refresh in progress: ${config.url}`);
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }).then(() => {
        logger.log(`Executing queued request for: ${config.url}`);
        return axios.request({
          ...config,
          headers: { ...config.headers, "X-CSRF-Token": getCsrfToken() }
        });
      });
    }

    isRefreshing = true;
    logger.log("Starting token refresh...");

    try {
      const newToken = await refreshCsrfToken();
      logger.log(`Token refresh completed. Processing queue (${failedQueue.length} items)`);
      processQueue();
      return axios.request({ ...config, headers: { ...config.headers, "X-CSRF-Token": newToken } });
    } catch (refreshError) {
      logger.error("Token refresh failed:", refreshError);
      processQueue(refreshError);
      return Promise.reject(refreshError);
    } finally {
      isRefreshing = false;
    }
  };

  const setupAxiosInterceptors = () => {
    axios.interceptors.response.use(
      response => response,
      error => handleCsrfError(error)
    );
  };

  const init = () => {
    logger.log("Initializing CSRF Manager");
    document.addEventListener("DOMContentLoaded", updateCsrfToken);
    document.addEventListener("page:load", updateCsrfToken);
    setupAxiosInterceptors();
    logger.log("CSRF Manager initialized");
  };

  const testSingleRequest = () => {
    logger.log("=== Single Request Test ===");
    logger.log("Starting test...");
    logger.log("Original token:", getCsrfToken());

    const invalidToken = "invalid-token";
    setCsrfToken(invalidToken);
    logger.log("Corrupted token:", getCsrfToken());

    return axios
      .post("/api/v1/browse/events/track_event", {
        type: "test_event",
        data: { timestamp: Date.now() }
      })
      .then(response => {
        logger.log("✅ Request succeeded:", response);
        logger.log("Final token:", getCsrfToken());
        logger.log("=== Test Complete ===");
      })
      .catch(error => {
        logger.error("❌ Request failed:", error);
        logger.log("Final token:", getCsrfToken());
        logger.log("=== Test Complete ===");
      });
  };

  const testConcurrentRequests = () => {
    logger.log("=== Concurrent Requests Test ===");
    logger.log("Starting test...");
    logger.log("Original token:", getCsrfToken());

    const invalidToken = "invalid-token";
    setCsrfToken(invalidToken);
    logger.log("Corrupted token:", getCsrfToken());

    return Promise.all([
      axios.post("/api/v1/browse/events/track_event", { type: "test_1", data: { id: 1 } }),
      axios.post("/api/v1/browse/events/track_event", { type: "test_2", data: { id: 2 } }),
      axios.post("/api/v1/browse/events/track_event", { type: "test_3", data: { id: 3 } })
    ])
      .then(responses => {
        logger.log("✅ All requests completed:", responses);
        logger.log("Final token:", getCsrfToken());
        logger.log("=== Test Complete ===");
      })
      .catch(error => {
        logger.error("❌ Some requests failed:", error);
        logger.log("Final token:", getCsrfToken());
        logger.log("=== Test Complete ===");
      });
  };

  return {
    init,
    setCsrfToken,
    refreshCsrfToken,
    test: {
      singleRequest: testSingleRequest,
      concurrentRequests: testConcurrentRequests
    }
  };
})();

if (process.env.NODE_ENV !== "production") {
  window.CSRFManager = CSRFManager;
}

export default CSRFManager;
