/* eslint-disable no-console */
/**
 * Logger utility for development and debugging
 *
 * Provides both default logging functionality and ability to create contextual loggers with prefixes.
 * All logging is controlled by window.LOGGER_ON flag or NODE_ENV.
 *
 * @example
 * // Default logging without prefix
 * Logger.log('Simple message');
 * Logger.error('Error occurred', errorObject);
 *
 * // Create contextual logger
 * const apiLogger = Logger.create('API');
 * apiLogger.log('Request started'); // outputs: [API] Request started
 *
 * // Using groups
 * Logger.group('Operation', () => {
 *   Logger.log('Step 1');
 *   Logger.log('Step 2');
 * });
 *
 * // Groups that always execute
 * apiLogger.groupAlways('Request', () => {
 *   makeRequest(); // This always runs
 *   apiLogger.log('Request complete'); // This only logs if enabled
 * });
 *
 * // Timing operations
 * Logger.time('Operation');
 * await someOperation();
 * Logger.timeEnd('Operation');
 *
 * // Debug data visualization
 * Logger.table(data);
 * Logger.dir(complexObject);
 */
const Logger = (() => {
  // Check if we're in a browser environment
  const isBrowser = typeof window !== "undefined";

  // Create a safe global context that works in both browser and SSR
  const globalContext = isBrowser
    ? window
    : {
        LOGGER_ON: false,
        // Stub console methods for SSR
        console: {
          log: () => {},
          warn: () => {},
          error: () => {},
          info: () => {},
          time: () => {},
          timeEnd: () => {},
          group: () => {},
          groupEnd: () => {},
          table: () => {},
          dir: () => {}
        }
      };

  // Set initial state
  globalContext.LOGGER_ON = isBrowser && process.env.NODE_ENV !== "production";

  /**
   * Checks if logging is currently enabled
   * @returns {boolean} True if logging is enabled
   */
  const isDebugEnabled = () => {
    return globalContext.LOGGER_ON;
  };

  /**
   * Format log arguments to handle both messages and data
   * @param {string} prefix - Logger prefix
   * @param {Array} args - Arguments to log
   * @returns {Array} Formatted arguments
   */
  const formatArgs = (prefix, args) => {
    if (!prefix) return args;

    // If first arg is a string, prepend prefix
    if (typeof args[0] === "string") {
      return [prefix + " " + args[0], ...args.slice(1)];
    }

    // If it's data/objects, keep prefix separate
    return [prefix, ...args];
  };

  /**
   * Creates a logger instance with optional prefix
   * @param {string} prefix - Optional prefix for all logs from this instance
   * @returns {Object} Logger instance
   */
  const createLogger = (prefix = "") => ({
    /**
     * Log information
     * @param {...any} args - Arguments to log
     * @example
     * logger.log('User logged in', { userId: 123 });
     */
    log: (...args) => {
      if (isDebugEnabled()) {
        console.log(...formatArgs(prefix, args));
      }
    },

    /**
     * Log warning
     * @param {...any} args - Arguments to log
     * @example
     * logger.warn('Deprecated feature used', { feature: 'oldAPI' });
     */
    warn: (...args) => {
      if (isDebugEnabled()) {
        console.warn(...formatArgs(prefix, args));
      }
    },

    /**
     * Log error
     * @param {...any} args - Arguments to log
     * @example
     * logger.error('Operation failed', error);
     */
    error: (...args) => {
      if (isDebugEnabled()) {
        console.error(...formatArgs(prefix, args));
      }
    },

    /**
     * Log info
     * @param {...any} args - Arguments to log
     * @example
     * logger.info('System status', { status: 'healthy' });
     */
    info: (...args) => {
      if (isDebugEnabled()) {
        console.info(...formatArgs(prefix, args));
      }
    },

    /**
     * Start a timer
     * @param {string} label - Timer label
     * @example
     * logger.time('Request');
     * await makeRequest();
     * logger.timeEnd('Request');
     */
    time: label => {
      if (isDebugEnabled()) {
        console.time(prefix ? `${prefix} ${label}` : label);
      }
    },

    /**
     * End a timer
     * @param {string} label - Timer label
     */
    timeEnd: label => {
      if (isDebugEnabled()) {
        console.timeEnd(prefix ? `${prefix} ${label}` : label);
      }
    },

    /**
     * Group logs together. Only executes if logging is enabled
     * @param {string} label - Group label
     * @param {Function} fn - Function containing logs to group
     * @example
     * logger.group('User Authentication', () => {
     *   logger.log('Checking credentials');
     *   logger.log('Validating token');
     * });
     */
    group: (label, fn) => {
      if (isDebugEnabled()) {
        console.group(prefix ? `${prefix} ${label}` : label);
        fn();
        console.groupEnd();
      }
    },

    /**
     * Group logs together, always executing the function
     * @param {string} label - Group label
     * @param {Function} fn - Function to execute (always runs)
     * @example
     * logger.groupAlways('Critical Operation', () => {
     *   performOperation(); // Always executes
     *   logger.log('Operation result'); // Only logs if enabled
     * });
     */
    groupAlways: (label, fn) => {
      if (isDebugEnabled()) {
        console.group(prefix ? `${prefix} ${label}` : label);
        fn();
        console.groupEnd();
      } else {
        fn();
      }
    },

    /**
     * Display tabular data
     * @param {Object|Array} data - Data to display
     * @param {Array<string>} [columns] - Columns to include
     * @example
     * logger.table([
     *   { id: 1, name: 'John' },
     *   { id: 2, name: 'Jane' }
     * ]);
     */
    table: (data, columns) => {
      if (isDebugEnabled()) {
        if (prefix) console.log(prefix);
        console.table(data, columns);
      }
    },

    /**
     * Display object structure
     * @param {Object} obj - Object to inspect
     * @param {Object} [options={ depth: null, colors: true }] - Display options
     * @example
     * logger.dir(complexObject, { depth: 2 });
     */
    dir: (obj, options = { depth: null, colors: true }) => {
      if (isDebugEnabled()) {
        if (prefix) console.log(prefix);
        console.dir(obj, options);
      }
    }
  });

  // Create default logger instance
  const defaultLogger = createLogger();

  /**
   * Controls for logger state
   */
  const controls = {
    /**
     * Enable all logging (including production)
     * @returns {void}
     */
    enable: () => {
      if (!isBrowser) return;
      globalContext.LOGGER_ON = true;
      console.log("Logger enabled (all environments)");
    },

    /**
     * Disable all logging
     * @returns {void}
     */
    disable: () => {
      if (!isBrowser) return;
      globalContext.LOGGER_ON = false;
      console.log("Logger disabled (all environments)");
    },

    /**
     * Get current logger state
     * @returns {Object} Current state of logger flags
     */
    status: () => ({
      enabled: globalContext.LOGGER_ON,
      isLogging: isDebugEnabled(),
      environment: process.env.NODE_ENV,
      isBrowser
    }),

    /**
     * Test if logging is working
     * @returns {void}
     */
    test: () => {
      if (!isBrowser) return;
      const status = controls.status();
      console.log("=== Logger Test ===");
      console.table(status);
      console.log("\nTesting log output:");
      const testLogger = Logger.create("TEST");
      testLogger.log("This message should appear if logging is enabled");
      console.log("=== Test Complete ===");
    }
  };

  const logger = {
    ...defaultLogger,
    /**
     * Create a new logger instance with a prefix
     * @param {string} prefix - Prefix for the new logger
     * @returns {Object} New logger instance
     * @example
     * const apiLogger = Logger.create('API');
     * apiLogger.log('Request started'); // outputs: [API] Request started
     */
    create: prefix => createLogger(`[${prefix}]`),
    controls
  };

  // Only attach to window if in browser environment
  if (isBrowser) {
    window.Logger = logger;
  }

  return logger;
})();

export default Logger;

// interface Window {
//   Logger: {
//     log: (...args: any[]) => void;
//     error: (...args: any[]) => void;
//     warn: (...args: any[]) => void;
//     info: (...args: any[]) => void;
//     time: (label: string) => void;
//     timeEnd: (label: string) => void;
//     group: (label: string, fn: () => void) => void;
//     groupAlways: (label: string, fn: () => void) => void;
//     table: (data: any, columns?: string[]) => void;
//     dir: (obj: any, options?: object) => void;
//     create: (prefix: string) => Logger;
//   };
//   LOGGER_ON: boolean;
// }

// examples/logger-usage.js

// import Logger from '../utils/logger';

// // Enable logging (in development/testing)
// window.LOGGER_ON = true;

// // Basic logging
// Logger.log('Application started');
// Logger.error('Something went wrong', new Error('Oops'));
// Logger.warn('Deprecated feature used');
// Logger.info('System status: OK');

// // Contextual logging
// const apiLogger = Logger.create('API');
// const authLogger = Logger.create('Auth');

// apiLogger.log('Making request'); // outputs: [API] Making request
// authLogger.error('Login failed'); // outputs: [Auth] Login failed

// // Timing operations
// Logger.time('Operation');
// someOperation();
// Logger.timeEnd('Operation');

// // Grouping related logs
// Logger.group('Initialization', () => {
//   Logger.log('Step 1: Loading config');
//   Logger.log('Step 2: Connecting to DB');
//   Logger.log('Step 3: Starting server');
// });

// // Grouping with always-execute operations
// authLogger.groupAlways('Login Process', () => {
//   const result = authenticateUser(); // Always runs
//   authLogger.log('Auth result:', result); // Only logs if enabled
// });

// // Debugging data structures
// const users = [
//   { id: 1, name: 'John' },
//   { id: 2, name: 'Jane' }
// ];
// Logger.table(users);

// const complexObject = {
//   data: { nested: { deep: { value: 42 } } }
// };
// Logger.dir(complexObject, { depth: 3 });

// // Mixed usage example
// function processUserLogin(credentials) {
//   const logger = Logger.create('UserAuth');

//   logger.groupAlways('Login', () => {
//     logger.time('Login Process');

//     try {
//       logger.log('Validating credentials');
//       validateCredentials(credentials);

//       logger.log('Making API request');
//       const result = makeLoginRequest(credentials);

//       logger.log('Login successful', result);
//     } catch (error) {
//       logger.error('Login failed', error);
//       throw error;
//     } finally {
//       logger.timeEnd('Login Process');
//     }
//   });
// }
