// Set common CSRF token for API calls (by picking it from Rails-generated csrf meta tag)
// Approach picked from https://github.com/reactjs/react-rails/issues/207#issuecomment-409593625

import axios from "axios";
import "../logger";
import CSRFManager from "./csrfToken";

window.pendingRequestCount = 0;

axios.interceptors.request.use(config => {
  window.pendingRequestCount += 1;
  return config;
}, Promise.reject);

axios.interceptors.response.use(
  response => {
    window.pendingRequestCount -= 1;
    return response;
  },
  error => {
    window.pendingRequestCount -= 1;
    return Promise.reject(error);
  }
);

CSRFManager.init();
